import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import blurredImage from "../../../../../assets/blurredImage.png";

function ImageSlider({ images }) {
  const options = {
    loop: true,
    margin: 10,
    nav: true,
    autoplay: true,
    autoplayTimeout: 3000,

    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
    navText: [
      '<i class="bi bi-chevron-left text-white fa-10x"></i>',
      '<i class="bi bi-chevron-right text-white"></i>',
    ],
  };
  return (
    <>
      <div className="flex gap-6 items-center my-6 overflow-x-scroll no-scrollbar">
        <OwlCarousel {...options}>
          {images &&
            images.map((item) => (
              <div key={item.title} className="w-full flex-shrink-0">
                <img
                  className="object-cover  lazyload"
                  loading="lazy"
                  src={blurredImage}
                  data-src={item.image}
                  alt={item.title}
                  style={{ width: "100%", height: "50vh" }}
                />
              </div>
            ))}
        </OwlCarousel>
      </div>
    </>
  );
}

export default ImageSlider;
