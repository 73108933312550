import React, { useState, useEffect } from "react";

import { ArtistCard, ArtistCardLoading } from "../../../cards/artistCards";
import { getAllPremiumArtists } from "../../../../../api/auth";
function AllPremiumArtists() {
  const [loading, setLoading] = useState();
  const [artists, setArtists] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const getContent = async () => {
      setLoading(true);
      const res = await getAllPremiumArtists(page);
      setLoading(false);
      if (res && res.status == 200) {
        setArtists(res.data);
      }
    };
    getContent();
  }, [page]);
  return (
    <div>
      <div className="w-full flex items-center justify-between">
        <div className="font-semibold text-white">Debut Artists</div>
        <div className="flex items-center">
          <div
            className="cursor-pointer"
            onClick={() => {
              if (page > 1) {
                setPage((page) => page - 1);
              }
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`h-6 w-6 ${"text-white"}`}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              setPage((page) => page + 1);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`h-6 w-6 ${"text-gray-300"}`}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </div>
        </div>
      </div>
      {loading && <PremiumArtistLoading />}
      <div className="w-full   flex   flex-wrap justify-evenly md:justify-start no-scrollbar gap-x-3 gap-y-4 mt-3 overflow-x-scroll ">
        {!loading &&
          artists.length > 0 &&
          artists.map((content) => (
            <ArtistCard content={content} premium={true} />
          ))}
      </div>
    </div>
  );
}
export const PremiumArtistLoading = () => {
  return (
    <div className="w-full    flex justify-evenly flex-wrap overflow-x-scroll  no-scrollbar gap-x-3 gap-y-4 mt-3">
      {Array(12)
        .fill(1)
        .map((el, index) => {
          return <ArtistCardLoading />;
        })}
    </div>
  );
};
export default AllPremiumArtists;
