import React, { useState, useEffect } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import { userStore } from "../../../../../stores/index";
function Navbar() {
  const [navigation, setNavigation] = useState("all");
  const history = useHistory();
  const location = useLocation();
  const user = userStore((state) => state.user);
  useEffect(() => {
    if (/^\/player\/premium\b[\/\w|\d]*/g.test(history.location.pathname)) {
      setNavigation("all");
    }
    if (
      /^\/player\/premium\/purchased\b[\/\w|\d]*/g.test(
        history.location.pathname
      )
    ) {
      setNavigation("purchased");
    }
  }, [location]);
  return (
    <div className=" flex  md:space-x-10">
      <div className="w-full flex items-center justify-between">
        {/* <div className="font-semibold text-green">Debut Artists</div> */}
        {/* <div className="flex items-center">
          <div className="cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`h-6 w-6 ${1 === 1 ? "text-white" : "text-gray-300"}`}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </div>
          <div className="cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`h-6 w-6 ${(true && "text-white") || "text-gray-300"}`}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </div>
        </div> */}
      </div>
      {/* <div className={"   cursor-pointer text-sm md:font-semibold text-green"}>
        <Link to={"/player/premium"}>Exclusive Artists</Link>
      </div> */}
      {/* <div className="cursor-pointer text-sm md:font-semibold text-white hover:text-green">
        Exclusive Albums
      </div>
      <div className="cursor-pointer text-sm md:font-semibold text-white hover:text-green">
        Exclusive Mixes
      </div> */}
    </div>
  );
}

export default Navbar;
