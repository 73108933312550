import { MusicLoader } from "../utils/musicLoaderSm"
export const LoadingModal = () => {

    return (
        <div
            className="fixed w-full inset-0 z-50 overflow-hidden flex justify-center items-end md:items-center animated fadeIn faster"
            style={{ background: "rgba(0,0,0,.7)" }}
        >
            <div className="shadow-lg modal-container w-full md:w-2/5 xl:w-2/5 h-1/4 mx-auto rounded-t-lg md:rounded-lg z-50 overflow-y-auto max-h-full">
                <div className="modal-content text-left h-full">
                    <div className="flex justify-center items-center h-full">
                        <div className="h-24 flex items-center justify-center">
                            <MusicLoader />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}