import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormInput } from "../form/FormInput";
import { MusicLoader } from "./musicLoaderSm";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import defaultArtist from "../../assets/defaultArtist.jpg";
import defaultSong from "../../assets/defaultSong.webp";
import { searchForAnything } from "../../api/auth";

export const Search = () => {
  const history = useHistory();
  const [searchField, setSearchField] = useState("");
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [searchResultLoading, setSearchResultLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [openSearch, setOpenSearch] = useState(false);

  useEffect(() => {
    if (searchField === "") {
      setShowSearchResult(false);
    } else {
      setShowSearchResult(true);
      setSearchResultLoading(true);
      searchForAnything({ query: searchField }).then((response) => {
        setSearchResultLoading(false);
        if (response && response.status === 200) {
          setSearchResults(response.data);
        } else {
          setSearchResults([]);
        }
      });
    }
  }, [searchField]);

  const openClickedResult = (url) => {
    setSearchResults([]);
    setShowSearchResult(false);
    setOpenSearch(false);
    history.push(url);
  };

  const clearSearch = () => {
    setSearchResults([]);
    setShowSearchResult(false);
  };

  const search = (input) => {
    setSearchField(input.target.value);
  };

  return (
    <div className="flex items-center ">
      <div className="w-full relative">
        <FormInput
          type={"text"}
          name={"search"}
          placeholder={"Search"}
          dataTestId={"search-input"}
          onchangeHandler={search}
          autoComplete={"off"}
          className="rounded-full py-1.5 hidden md:block"
        />
        {showSearchResult && !searchResultLoading && (
          <div className="absolute w-full flex flex-col h-screen bg-white overflow-y-scroll z-50 shadow-md">
            {searchResults?.artists?.length > 0 && (
              <div className="flex flex-col gap-y-2 mt-2">
                <div className="text-sm font-semibold text-gray-600 px-3 pt-2">
                  Top result
                </div>
                {
                  <div
                    className="w-full flex justify-between items-center hover:bg-gray-100 py-2 px-3 cursor-pointer group"
                    onClick={() => {
                      openClickedResult(
                        `/player/browse/artist/${searchResults.artists[0]._id}`
                      );
                    }}
                  >
                    <div className="flex items-center space-x-2">
                      <div>
                        <img
                          src={
                            searchResults.artists[0]?.image
                              ? searchResults.artists[0]?.image.includes(
                                  "cloudinary"
                                ) ||
                                !searchResults.artists[0]?.image.includes(
                                  "prod"
                                )
                                ? defaultArtist
                                : searchResults.artists[0]?.image
                              : defaultArtist
                          }
                          className="w-10 h-10 rounded-full cursor-pointer object-cover"
                          alt="User profile"
                        />
                      </div>
                      <div className="flex flex-col justify-center">
                        <div className="text-sm capitalize font-semibold  text-bloow-gray group-hover:underline">
                          {(searchResults.artists[0] &&
                            searchResults.artists[0].names) ||
                            (searchResults.artists[0] &&
                              searchResults.artists[0].email &&
                              searchResults.artists[0].email.split("@")[0])}
                        </div>
                        <div className="text-xs text-gray-600">Artist</div>
                      </div>
                    </div>
                    <div className="flex space-x-2">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 text-gray-500"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                          />
                        </svg>
                      </div>
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 text-gray-500"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                }
              </div>
            )}
            {searchResults?.artists?.length > 0 && (
              <div className="flex flex-col gap-y-2 mt-2">
                <div className="text-sm font-semibold text-gray-600 mt-4 px-3 ">
                  Artists
                </div>
                {searchResults.artists.map((user, key) => {
                  if (key !== 0) {
                    return (
                      <div
                        key={key}
                        className="w-full flex justify-between px-3 py-2 group hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          openClickedResult(
                            `/player/browse/artist/${user._id}`
                          );
                        }}
                      >
                        <div className="flex items-center space-x-2">
                          <div>
                            <img
                              src={
                                user?.image
                                  ? user?.image.includes("cloudinary") ||
                                    !user?.image.includes("prod")
                                    ? defaultArtist
                                    : user?.image
                                  : defaultArtist
                              }
                              className="w-10 h-10 rounded-full cursor-pointer object-cover"
                              alt="User profile"
                            />
                          </div>
                          <div className="flex flex-col justify-center">
                            <div className="text-sm capitalize font-semibold text-bloow-gray group-hover:underline">
                              {(user && user.names) ||
                                (user &&
                                  user.email &&
                                  user.email.split("@")[0])}
                            </div>
                            <div className="text-xs text-gray-600">Artist</div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            )}

            {searchResults?.contents?.length > 0 && (
              <div className="flex flex-col gap-y-2 mt-2">
                <div className="text-sm font-semibold text-gray-600 mt-4 px-3 ">
                  Songs
                </div>
                {searchResults.contents.map((content, key) => {
                  return (
                    <div
                      key={key}
                      className="w-full flex items-center justify-between px-3 py-1 group hover:bg-gray-100 cursor-pointer"
                      onClick={() => {
                        openClickedResult(
                          `/player/browse/content/${content._id}`
                        );
                      }}
                    >
                      <div className="flex items-center space-x-2">
                        <div>
                          <img
                            src={
                              content?.thumbnailPath
                                ? content?.thumbnailPath.includes(
                                    "cloudinary"
                                  ) || !content?.thumbnailPath.includes("prod")
                                  ? defaultSong
                                  : content?.thumbnailPath
                                : defaultSong
                            }
                            className="w-12 h-12 rounded-md cursor-pointer object-cover"
                            alt="Song artwork"
                          />
                        </div>
                        <div className="flex flex-col justify-center">
                          <div className="text-sm capitalize font-semibold text-bloow-gray group-hover:underline">
                            {content && content.contentName}
                          </div>
                        </div>
                      </div>
                      <div className="flex space-x-2">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 text-gray-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
        {showSearchResult && searchResultLoading && (
          <div className="absolute w-full hidden md:flex flex-col items-center p-4 h-16">
            <MusicLoader />
          </div>
        )}
      </div>
      {(showSearchResult && !searchResultLoading && (
        <div
          className="-ml-8  z-10 hover:bg-gray-100 rounded-md cursor-pointer"
          onClick={clearSearch}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-gray-600 font-extrabold"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
      )) || (
        <>
          <div className="hidden md:-ml-8 z-10" onClick={openSearch}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-gray-600 font-extrabold"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>
          {(!openSearch && (
            <div
              className="block md:hidden md:-ml-8 z-10 transition-all"
              onClick={() => {
                setOpenSearch(true);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-gray-600 font-extrabold"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
          )) || (
            <div
              className="block md:hidden md:-ml-8 z-10 transition-all"
              onClick={() => {
                setOpenSearch(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-gray-500 font-extrabold"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          )}
        </>
      )}
      <SlidingPane
        isOpen={openSearch}
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setOpenSearch(false);
        }}
        from="right"
        width="100%"
        hideHeader={true}
      >
        <div className="h-screen absolute top-0 flex flex-col z-100 pt-6 w-full  -mx-8  bg-midnight">
          <div className="pt-6 px-2 h-4/5 flex flex-col">
            <div className=" text-bloow-blue font-black text-lg text-center">
              Search
            </div>
            <div className="pb-2">
              <FormInput
                type={"text"}
                name={"search"}
                placeholder={""}
                dataTestId={"search-input"}
                onchangeHandler={search}
                autoComplete={"off"}
                className="rounded-md block md:hidden"
              />
            </div>
            {!showSearchResult && (
              <div className="flex-grow w-full text-white  h-1/2 flex flex-col items-center justify-center">
                <div className="flex justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-16 w-16 "
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </div>
                <div className=" font-bold text-center">
                  Search for Artists, Tracks, Playlists & Albums
                </div>
              </div>
            )}
            {showSearchResult && !searchResultLoading && (
              <div className=" w-full flex text-white flex-col  h-screen overflow-y-scroll overflow-x-hidden border rounded-lg border-white">
                {searchResults?.artists?.length > 0 && (
                  <div className="flex flex-col gap-y-2 mt-2">
                    <div className="text-sm font-semibold  px-3 pt-2">
                      Top result
                    </div>
                    {
                      <div
                        className="w-full flex justify-between items-center hover:bg-gray-100 py-2 px-3 cursor-pointer group"
                        onClick={() => {
                          openClickedResult(
                            `/player/browse/artist/${searchResults.artists[0]._id}`
                          );
                        }}
                      >
                        <div className="flex items-center space-x-2">
                          <div>
                            <img
                              src={
                                searchResults.artists[0]?.image
                                  ? searchResults.artists[0]?.image.includes(
                                      "cloudinary"
                                    ) ||
                                    !searchResults.artists[0]?.image?.includes(
                                      "prod"
                                    )
                                    ? defaultArtist
                                    : searchResults.artists[0]?.image
                                  : defaultArtist
                              }
                              className="w-10 h-10 rounded-full cursor-pointer object-cover"
                              alt="User profile"
                            />
                          </div>
                          <div className="flex flex-col justify-center">
                            <div className="text-sm capitalize font-semibold text-green group-hover:underline">
                              {(searchResults.artists[0] &&
                                searchResults.artists[0].names) ||
                                (searchResults.artists[0] &&
                                  searchResults.artists[0].email &&
                                  searchResults.artists[0].email.split("@")[0])}
                            </div>
                            <div className="text-xs ">Artist</div>
                          </div>
                        </div>
                        <div className="flex space-x-2">
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6 "
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth={2}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                              />
                            </svg>
                          </div>
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6 text-gray-500"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth={2}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                )}
                {searchResults?.artists?.length > 0 && (
                  <div className="flex flex-col gap-y-2 mt-2">
                    <div className="text-sm font-semibold  mt-4 px-3 ">
                      Artists
                    </div>
                    {searchResults.artists.map((user, key) => {
                      if (key !== 0) {
                        return (
                          <div
                            key={key}
                            className="w-full flex justify-between px-3 py-2 group hover:bg-gray-100 cursor-pointer"
                            onClick={() => {
                              openClickedResult(
                                `/player/browse/artist/${user._id}`
                              );
                            }}
                          >
                            <div className="flex items-center space-x-2">
                              <div>
                                <img
                                  src={
                                    user?.image
                                      ? user?.image.includes("cloudinary") ||
                                        !user?.image.includes("prod")
                                        ? defaultArtist
                                        : user?.image
                                      : defaultArtist
                                  }
                                  className="w-10 h-10 rounded-full cursor-pointer object-cover"
                                  alt="User profile"
                                />
                              </div>
                              <div className="flex flex-col justify-center">
                                <div className="text-sm capitalize font-semibold text-green group-hover:underline">
                                  {(user && user.names) ||
                                    (user &&
                                      user.email &&
                                      user.email.split("@")[0])}
                                </div>
                                <div className="text-xs ">
                                  <span className="">Artist</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                )}

                {searchResults?.contents?.length > 0 && (
                  <div className="flex flex-col gap-y-2 mt-2">
                    <div className="text-sm font-semibold  mt-4 px-3 ">
                      Songs
                    </div>
                    {searchResults.contents.map((content, key) => {
                      return (
                        <div
                          key={key}
                          className="w-full flex items-center justify-between px-3 py-1 group hover:bg-gray-100 cursor-pointer"
                          onClick={() => {
                            openClickedResult(
                              `/player/browse/content/${content._id}`
                            );
                          }}
                        >
                          <div className="flex items-center space-x-2">
                            <div>
                              <img
                                src={
                                  content?.thumbnailPath
                                    ? content?.thumbnailPath.includes(
                                        "cloudinary"
                                      ) ||
                                      !content?.thumbnailPath.includes("prod")
                                      ? defaultSong
                                      : content?.thumbnailPath
                                    : defaultSong
                                }
                                className="w-12 h-12 rounded-md cursor-pointer object-cover"
                                alt="Song artwork"
                              />
                            </div>
                            <div className="flex flex-col justify-center">
                              <div className="text-sm capitalize font-semibold text-green group-hover:underline">
                                {content && content.contentName}
                              </div>
                              <div className="text-xs  ">Song name</div>
                            </div>
                          </div>
                          <div className="flex space-x-2">
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 "
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
            {showSearchResult &&
              !searchResultLoading &&
              searchResults?.length === 0 && (
                <div className="flex-grow w-full bg-white h-1/2 flex flex-col items-center justify-center">
                  No results found...
                </div>
              )}
            {showSearchResult && searchResultLoading && (
              <div className="flex-grow w-full bg-white h-1/2 flex flex-col items-center justify-center">
                Loading...
              </div>
            )}
          </div>
        </div>
      </SlidingPane>
    </div>
  );
};
