export const FormSelect = ({
  label,
  name,
  items,
  onchangeHandler,
  light,
  value,
  dark,
  other,
  className,
}) => {
  return (
    <div>
      <label
        htmlFor={name}
        className={
          className
            ? className
            : `text-xs ${light === false ? "font-medium" : "font-semibold"}`
        }
      >
        {label}
      </label>
      <select
        name={name}
        className={
          dark
            ? "w-full rounded-md  border focus:outline-none focus:ring-0 focus:border-bloow-blue text-black"
            : "w-full rounded-md  border focus:outline-none focus:ring-0 focus:border-bloow-blue"
        }
        onChange={onchangeHandler}
      >
        {!value ? (
          <option value="">Select {label}</option>
        ) : (
          <option value={value}>{value}</option>
        )}
        {items.length > 0 ? (
          <>
            {items.map((item) => {
              return (
                <option value={item.value ? item.value : item.name}>
                  {item.name}
                </option>
              );
            })}

            {other && <option value="other">Other</option>}
          </>
        ) : (
          <option value=""> Loading...</option>
        )}
      </select>
    </div>
  );
};
