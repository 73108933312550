import { useState, useEffect } from "react";
import { Search } from "../../../../components/utils/search";
import { NavProfile } from "../../../../components/utils/navProfile";
import BrowseNavigation from "./subnavbar/browseNavigation";
import { useHistory, useLocation } from "react-router-dom";
import Login from "../../../../components/auth/login";
import SignUp from "../../../../components/auth/signup";
import logo from "../../../../assets/logo.svg";
import { Link } from "react-router-dom";
import { MdMenu } from "react-icons/md";
import { useOpenSidebar } from "../../../../stores";

export const Navbar = () => {
  const [navigation, setNavigation] = useState();
  const [showSignIn, setShowSignIn] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const setOpenSidebar = useOpenSidebar((state) => state.setOpenSidebar);
  const openSidebar = useOpenSidebar((state) => state.openSidebar);

  useEffect(() => {
    if (/^\/player\b\/*$/g.test(history.location.pathname)) {
      setNavigation("home");
    } else if (
      /^\/player\/browse\b[\/\w|\d]*/g.test(history.location.pathname)
    ) {
      setNavigation("browse");
    } else if (
      /^\/player\/library\b[\/\w|\d]*/g.test(history.location.pathname)
    ) {
      setNavigation("library");
    } else if (
      /^\/player\/queue\b[\/\w|\d]*/g.test(history.location.pathname)
    ) {
      setNavigation("queue");
    } else {
      setNavigation("queue");
    }
  }, [location]);

  const mode = {
    light: {
      text: "text-bloow-black",
      background: "bg-white",
      navLink: "text-black",
      navText: "text-bloow-blue",
    },
    dark: {
      text: "text-white",
      background: "bg-bloow-blue",
      navLink: "text-black",
      navText: "text-white",
    },
  };

  return (
    <div
      className={`absolute top-0 z-50  w-full  flex items-center justify-between  px-4 py-4 `}
    >
      <div
        className=" text-3xl text-white font-bold"
        onClick={() => setOpenSidebar(!openSidebar)}
      >
        <MdMenu />
      </div>
      <div className="flex items-center space-x-2">
        <div>
          <img src={logo} className="w-12" />
        </div>
        {/* <Link to={"/"}>
          <div className="text-white font-black">Bloow</div>
        </Link> */}
      </div>
      <Search />
      {showSignIn && (
        <Login setShowSignIn={setShowSignIn} setShowSignUp={setShowSignUp} />
      )}
      {showSignUp && (
        <SignUp
          setShowSignIn={setShowSignIn}
          setShowSignUp={setShowSignUp}
          userType={"artist"}
        />
      )}
    </div>
  );
};
