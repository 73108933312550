import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
const BrowseNavigation = () => {
  const [subNav, setSubNav] = useState();
  const history = useHistory();
  useEffect(() => {
    if (
      /^\/player\/browse\/genre\b[\/\w|\d]*/g.test(history.location.pathname)
    ) {
      setSubNav("genres");
    } else if (
      /^\/player\/browse\/newreleases\b[\/\w|\d]*/g.test(
        history.location.pathname
      )
    ) {
      setSubNav("newReleases");
    } else if (
      /^\/player\/browse\/podcasts\b[\/\w|\d]*/g.test(history.location.pathname)
    ) {
      setSubNav("podcasts");
    }
  }, []);
  return (
    <div className="flex text-white">
      <Link to="/player/browse/genre">
        <div
          className={
            subNav == "genres"
              ? "pr-3 text-sm font-black cursor-pointer"
              : "pr-3 text-sm cursor-pointer"
          }
          onClick={() => setSubNav("genres")}
        >
          Genres & Moods{" "}
        </div>
      </Link>
      <Link to="/player/browse/newreleases">
        <div
          className={
            subNav == "newReleases"
              ? "pr-3 text-sm font-black cursor-pointer"
              : "pr-3 text-sm cursor-pointer"
          }
          onClick={() => setSubNav("newReleases")}
        >
          New Realeses{" "}
        </div>
      </Link>
      <Link to="/player/browse/podcasts">
        <div
          className={
            subNav == "podcasts"
              ? "pr-3 text-sm font-black cursor-pointer"
              : "pr-3 text-sm cursor-pointer"
          }
          onClick={() => setSubNav("podcasts")}
        >
          Podcasts{" "}
        </div>
      </Link>
    </div>
  );
};

export default BrowseNavigation;
