import React, { useState, useEffect, Suspense, lazy, useRef } from "react";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { userStore, useTippingMpesa, useTippingPaypal } from "../../../stores";
import { likeContent } from "../../../api/auth";

import { LoadingModal } from "../../modals/loadingModal";
import { usePlaylist } from "../../../stores/index";
import { Link } from "react-router-dom";
import defaultImage from "../../../assets/defaultSong.webp";
import blurredContent from "../../../assets/blurredContent.jpg";

const Login = lazy(() => import("../../auth/login"));
const SignUp = lazy(() => import("../../auth/signup"));
const TippingPaymentModal = lazy(() =>
  import("../../modals/tippingPaymentModal")
);
const PaypalTippingModal = lazy(() =>
  import("../../modals/paypalTippingModal")
);
const VotingModal = lazy(() => import("../../modals/votingModal"));

export const ContentCard = ({ content }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [liked, setLiked] = useState();
  const [likesCount, setLikesCount] = useState();
  const [loginText, setLoginText] = useState("");
  const [showSignUp, setShowSignUp] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const [showVotingModal, setShowVotingModal] = useState(false);
  const updatePlaylist = usePlaylist((state) => state.setPlaylist);
  const setIsPlaying = usePlaylist((state) => state.setIsPlaying);
  const mpesa = useTippingMpesa((state) => state.mpesa);
  const user = userStore((state) => state.user);
  const paypal = useTippingPaypal((state) => state.paypal);
  const setMpesa = useTippingMpesa((state) => state.setMpesa);
  const ref = useRef();
  UseOnClickOutside(ref, () => setShowMenu(false));
  useEffect(() => {
    setLikesCount(content?.likes?.length);
    if (user) {
      setLiked(
        user?.likedContent?.find((like) => {
          return like.contentId == content._id;
        })
      );
    }
  }, [user]);

  const likingContent = () => {
    if (user) {
      likeContent({ contentId: content._id }).then((response) => {});
      if (liked) {
        setLiked(!liked);
        setLikesCount(likesCount - 1);
      } else {
        setLiked(!liked);
        setLikesCount(likesCount + 1);
      }
    } else {
      setShowSignIn(true);
      setLoginText("Please authenticate to like a content.");
    }
  };
  const showMoreMenu = () => {
    setShowMenu((prevState) => !prevState);
  };
  const closeVotingHandler = () => {
    setShowVotingModal(false);
  };
  return (
    <>
      <div
        className="w-44 h-full flex-shrink-0 flex flex-col group hover:bg-gray-200 bg-midnight-light ease-in-out duration-300 p-2 cursor-pointer rounded-md group"
        onClickCapture={() => {
          updatePlaylist(content);
          setIsPlaying(true);
          showMoreMenu();
        }}
        ref={ref}
      >
        <div className="w-full h-3/4 flex-shrink-0">
          <img
            className="object-cover w-full h-full rounded-md lazyload"
            loading="lazy"
            src={blurredContent}
            data-src={
              content?.thumbnailPath
                ? (content?.thumbnailPath?.includes("cloudinary") &&
                    defaultImage) ||
                  (!content?.thumbnailPath?.includes("prod") && defaultImage) ||
                  content?.thumbnailPath
                : content?.albumId?.albumBackgroundPath
            }
            alt="content"
          />
          <div className="w-full absolute backdrop-filter backdrop-blur-xl backdrop-contrast-50"></div>
        </div>
        <div className=" flex flex-col ">
          <div className="w-full text-xs py-0.5 font-semibold text-white group-hover:text-black  line-clamp-1">
            {content?.contentName.replace(".mp3", "")}
          </div>
          <div className=" text-xss pb-0.5 text-gray-400 group-hover:text-black">
            {content?.userId?.names || content?.userId?.email?.split("@")[0]}
          </div>

          <div className="w-full relative flex gap-x-2 items-center">
            {liked && <AiFillHeart className="mr-1 text-red-500 " />}
            {!liked && <AiOutlineHeart className="mr-1 text-red-500 " />}
            <div className="text-xs text-white group-hover:text-black">
              {likesCount}
            </div>
            <div className="absolute right-0 hidden group-hover:block ease-in-out duration-300">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                />
              </svg>
            </div>
            <div
              className={`${
                (showMenu && "flex") || "hidden"
              } absolute right-4 -top-24 z-100 ease-in-out duration-300 flex-col w-32 gap-y-2 px-0.5 py-0.5 bg-white text-xs`}
            >
              <Link to={`/player/browse/artist/${content?.userId?._id}`}>
                <div className="px-4 py-2 hover:bg-gray-300" onClick={() => {}}>
                  View Artist
                </div>
              </Link>
              <div
                className="px-4 py-2 hover:bg-gray-300"
                onClick={() => setShowVotingModal(true)}
              >
                Tip
              </div>
              <div
                className="px-4 py-2 hover:bg-gray-300"
                onClick={likingContent}
              >
                {liked && "unLike song"}
                {!liked && "like song"}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showVotingModal && (
        <Suspense fallback={<LoadingModal />}>
          <TippingPaymentModal
            id={content._id}
            closeHandler={closeVotingHandler}
          />
        </Suspense>
      )}
      {mpesa && (
        <Suspense fallback={<LoadingModal />}>
          <VotingModal
            closeHandler={() => setMpesa(false)}
            contentId={content._id}
            content={content}
          />
        </Suspense>
      )}
      {paypal && (
        <Suspense fallback={<LoadingModal />}>
          <PaypalTippingModal artistId={content?.userId?._id} />
        </Suspense>
      )}

      {!user && showSignUp && (
        <Suspense fallback={<LoadingModal />}>
          <SignUp
            setShowSignUp={setShowSignUp}
            setShowSignIn={setShowSignIn}
            userType={"basic"}
          />
        </Suspense>
      )}
      {!user && showSignIn && (
        <Suspense fallback={<LoadingModal />}>
          <Login
            setShowSignIn={setShowSignIn}
            setShowSignUp={setShowSignUp}
            loginText={loginText}
          />
        </Suspense>
      )}
    </>
  );
};
export const ContentRoundCard = ({ content, onClickHandler }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [liked, setLiked] = useState();
  const [likesCount, setLikesCount] = useState(0);
  const [loginText, setLoginText] = useState("");
  const [showSignUp, setShowSignUp] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const [showVotingModal, setShowVotingModal] = useState(false);
  const updatePlaylist = usePlaylist((state) => state.setPlaylist);
  const setIsPlaying = usePlaylist((state) => state.setIsPlaying);

  const playlist = usePlaylist((state) => state.playlist);
  const mpesa = useTippingMpesa((state) => state.mpesa);
  const user = userStore((state) => state.user);
  const paypal = useTippingPaypal((state) => state.paypal);
  const setMpesa = useTippingMpesa((state) => state.setMpesa);
  const ref = useRef();
  UseOnClickOutside(ref, () => setShowMenu(false));
  useEffect(() => {
    setLikesCount(content?.likes?.length);
    if (user) {
      setLiked(
        user?.likedContent?.find((like) => {
          return like.contentId == content._id;
        })
      );
    }
  }, [user]);

  const likingContent = () => {
    if (user) {
      likeContent({ contentId: content._id }).then((response) => {});
      if (liked) {
        setLiked(!liked);
        setLikesCount(likesCount - 1);
      } else {
        setLiked(!liked);
        setLikesCount(likesCount + 1);
      }
    } else {
      setShowSignIn(true);
      setLoginText("Please authenticate to like a content.");
    }
  };
  const showMoreMenu = () => {
    setShowMenu((prevState) => !prevState);
  };
  const closeVotingHandler = () => {
    setShowVotingModal(false);
  };
  return (
    <>
      <div
        className="hover:bg-gray-200 text-white w-44 flex-shrink-0  hover:text-gray-600 bg-midnight-light ease-in-out duration-300 p-3 cursor-pointer rounded-md group"
        onClick={showMoreMenu}
        ref={ref}
      >
        <div className="w-36 h-full flex-shrink-0 flex flex-col items-center justify-center">
          <img
            className="object-cover w-full h-36 rounded-full lazyload"
            loading="lazy"
            src={blurredContent}
            data-src={
              content?.thumbnailPath
                ? (content?.thumbnailPath?.includes("cloudinary") &&
                    defaultImage) ||
                  (!content?.thumbnailPath?.includes("prod") && defaultImage) ||
                  content?.thumbnailPath
                : content?.albumId?.albumBackgroundPath
            }
            alt="content"
          />
          <div className="w-full h-1/4">
            <div className="text-sm text-center font-semibold my-1">
              {content?.contentName.replace(".mp3", "")}
            </div>
            <div className="w-full relative flex gap-x-2 items-center justify-center">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  viewBox="0 0 20 20"
                  fill="red"
                >
                  <path
                    fillRule="evenodd"
                    d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="text-xs">{likesCount}</div>
              <div className="absolute right-0 hidden group-hover:block ease-in-out duration-300">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                  />
                </svg>
              </div>
              <div
                className={`${
                  (showMenu && "flex") || "hidden"
                } absolute right-4 -top-24 z-100 ease-in-out duration-300 flex-col w-32 gap-y-2 px-0.5 py-0.5 bg-white text-xs`}
              >
                <Link to={`/player/browse/artist/${content?.userId?._id}`}>
                  <div className="px-4 py-2 hover:bg-gray-300">View Artist</div>
                </Link>
                <div
                  className="px-4 py-2 hover:bg-gray-300"
                  onClick={() => setShowVotingModal(true)}
                >
                  Tip
                </div>
                <div
                  className="px-4 py-2 hover:bg-gray-300"
                  onClick={likingContent}
                >
                  {liked && "UnLike song"}
                  {!liked && "Like song"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showVotingModal && (
        <Suspense fallback={<LoadingModal />}>
          <TippingPaymentModal
            id={content._id}
            closeHandler={closeVotingHandler}
          />
        </Suspense>
      )}
      {mpesa && (
        <Suspense fallback={<LoadingModal />}>
          <VotingModal
            closeHandler={() => setMpesa(false)}
            contentId={content._id}
            content={content}
          />
        </Suspense>
      )}
      {paypal && (
        <Suspense fallback={<LoadingModal />}>
          <PaypalTippingModal artistId={content?.userId?._id} />
        </Suspense>
      )}

      {!user && showSignUp && (
        <Suspense fallback={<LoadingModal />}>
          <SignUp
            setShowSignUp={setShowSignUp}
            setShowSignIn={setShowSignIn}
            userType={"basic"}
          />
        </Suspense>
      )}
      {!user && showSignIn && (
        <Suspense fallback={<LoadingModal />}>
          <Login
            setShowSignIn={setShowSignIn}
            setShowSignUp={setShowSignUp}
            loginText={loginText}
          />
        </Suspense>
      )}
    </>
  );
};
export const ContentCardLoading = () => {
  return (
    <div className="flex flex-col p-3 border rounded-md gap-y-3">
      <div className="w-36 h-full bg-gray-300 animate-pulse rounded-md"></div>
      <div className="w-3/4 h-7 bg-gray-300 animate-pulse rounded-md"></div>
      <div className="w-1/4 h-7 bg-gray-300 animate-pulse rounded-md"></div>
    </div>
  );
};
export const ContentRoundCardLoading = () => {
  return (
    <div className="flex flex-col items-center border p-3 rounded-md gap-y-1.5">
      <div className="w-36 h-full bg-gray-300 animate-pulse rounded-full"></div>
      <div className="w-3/4 h-7 bg-gray-300 animate-pulse rounded-md"></div>
      <div className="w-1/4 h-7 bg-gray-300 animate-pulse rounded-md"></div>
    </div>
  );
};
function UseOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}
