import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import music from "../../assets/drummer.jpg";
import { FormInput } from "../form/FormInput";
import { FormSelect } from "../form/FormSelect";
import { MainSubmitButton } from "../buttons/MainSubmitButton";
import { registerUser, sendOTPMessage, verifyOTP } from "../../api/auth";
import { userStore } from "../../stores";
import { Feedback } from "../../components/utils/feedback";
import ReactFlagsSelect from "react-flags-select";
import { Spinner } from "../../components/utils/spinningLoaderBlack";
import { getClientInfoFromIp } from "../../api/ip";

let cleanedPhone = null;

export const SignUp = ({ setShowSignUp, setShowSignIn, userType }) => {
  const initialize = {
    email: "",
    password: "",
    confirmPassword: "",
    currency: "",
    country: "",
    role: "",
    terms: false,
  };
  const countries = {
    KE: "+254",
    NG: "+234",
    TZ: "+255",
    SA: "+27",
  };
  const history = useHistory();
  const storeUser = userStore((state) => state.storeUser);
  const [
    {
      email,
      password,
      confirmPassword,
      country,
      currency,
      role,
      terms,
      countryCode,
    },
    setUser,
  ] = useState(initialize);
  const [formError, setFormError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [{ feedbackMessage, feedbackText }, setFeedBack] = useState({
    feedbackMessage: "",
    feedbackText: "",
  });
  const [signupArtistImg, setSignupArtistImg] = useState(false);
  const [countrySelected, setCountrySelected] = useState("");
  const [countryCodeSelected, setCountryCodeSelected] = useState("");
  const [supportedCountries] = useState(countries);
  const [phoneVerified, setPhoneVerified] = useState(false);

  // useEffect(() => {
  //   getClientInfoFromIp().then((response) => {
  //     if (response && response.status === 200) {
  //       selectCountry(response.data.country);
  //       setUser((prevState) => ({
  //         ...prevState,
  //         currency: response.data.currency,
  //         country: response.data.country,
  //         countryCode: response.data.country_calling_code,
  //       }));
  //     }
  //   });
  // }, []);

  const handleChange = (input) => {
    setUser((prevState) => ({
      ...prevState,
      [input.target.name]: input.target.value,
    }));
  };
  const toggleSignUp = () => {
    setFormError(false);
    setShowSignUp((prevState) => !prevState);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!validateInputs(e.target)) {
      setIsLoading(false);
      setFormError(true);
      setFeedBack({
        feedbackMessage: "Please provide correct information",
        feedbackText: "text-red-500",
      });
      return false;
    }
    registerUser({ email: email, password: password, role: role }).then(
      (response) => {
        setIsLoading(false);
        if (response && response.status === 200) {
          setFormError(false);
          storeUser(response.data.user);

          if (role === "artist") {
            history.push("/dashboard/artist");
          } else {
            history.push("/player");
          }
        } else {
          setFeedBack({
            feedbackMessage: response.data.message,
            feedbackText: "text-red-500",
          });
          setFormError(true);
        }
      }
    );
  };

  const showImg = () => {
    setSignupArtistImg(true);
  };

  const validateInputs = (form) => {
    for (let i = 0; i < form.target.length; i++) {
      if (
        (form.target[i].localName === "input" && form.target[i].value === "") ||
        form.target[i].value === undefined ||
        form.target[i].value === null
      ) {
        return false;
      }
      if (
        form.target[i].localName === "input" &&
        form.target[i].type === "email" &&
        !form.target[i].value.includes("@")
      ) {
        return false;
      }
    }
    const passwords = Array.from(form).filter(
      (input) => input.type === "password"
    );
    if (passwords[0].value !== passwords[1].value) {
      return false;
    }
    return true;
  };

  const toggleSignIn = () => {
    setShowSignUp(false);
    setShowSignIn(true);
  };

  const selectCountry = (e) => {
    setCountryCodeSelected(e);
    setCountrySelected(supportedCountries[e]);
    if (supportedCountries[e] === undefined) {
      setFormError(true);
      setFeedBack({
        feedbackMessage: "Sorry we do not support your country yet",
        feedbackText: "text-red-500",
      });
    } else {
      setFormError(false);
    }
  };

  const checkMissingFields = () => {
    if (email === "") {
      setFormError(true);
      setFeedBack({
        feedbackMessage: "Email is required",
        feedbackText: "text-red-500",
      });
      return;
    }
    if (password === "") {
      setFormError(true);
      setFeedBack({
        feedbackMessage: "Password is required",
        feedbackText: "text-red-500",
      });
      return;
    }
    if (role === "") {
      setFormError(true);
      setFeedBack({
        feedbackMessage: "Role is required",
        feedbackText: "text-red-500",
      });
      return;
    }
    if (confirmPassword === "") {
      setFormError(true);
      setFeedBack({
        feedbackMessage: "Confirm password is required",
        feedbackText: "text-red-500",
      });
      return;
    }
    if (password !== confirmPassword) {
      setFormError(true);
      setFeedBack({
        feedbackMessage: "Passwords don't match",
        feedbackText: "text-red-500",
      });
      return;
    }

    setFormError(false);
  };
  return (
    <div
      className="fixed w-full text-gray-500 inset-0 z-100 overflow-hidden flex justify-center items-end md:items-center animated fadeIn faster"
      style={{ background: "rgba(0,0,0,.7)" }}
    >
      <div className="shadow-lg modal-container bg-white w-full  md:w-3/5 md:max-w-11/12 mx-auto rounded-t-lg md:rounded-lg shadow-lg z-100 overflow-y-auto max-h-full">
        <div className="modal-content text-left">
          <div className="flex justify-between">
            <div className="hidden md:flex items-center w-full h-auto bg-blue-50">
              <img
                src={music}
                alt=""
                className={`w-full h-full ${signupArtistImg ? "" : "hidden"}`}
                onLoad={showImg}
              />
            </div>
            <div className="w-full">
              <div className="flex justify-end p-5 pb-3">
                <svg
                  onClick={toggleSignUp}
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-gray-500 cursor-pointer"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="w-full flex justify-center">
                <div className="w-3/4 flex flex-col ">
                  <div className="text-xl xl:text-3xl text-center md:text-left font-bold text-gray-700">
                    Welcome to Bloow
                  </div>
                  {formError && (
                    <Feedback message={feedbackMessage} type={feedbackText} />
                  )}
                  <div className="py-4 pb-8">
                    <form onSubmit={handleSubmit}>
                      <div className="flex flex-col space-y-4">
                        <FormInput
                          label={"Email"}
                          type={"email"}
                          name={"email"}
                          placeholder={"grishon@example.com"}
                          dataTestId={"email-input"}
                          onchangeHandler={handleChange}
                          className="text-black"
                          required={true}
                        />

                        <FormInput
                          label={"Password"}
                          type={"password"}
                          name={"password"}
                          placeholder={"strong password"}
                          dataTestId={"password-input"}
                          onchangeHandler={handleChange}
                          className="text-black"
                          required={true}
                        />
                        <FormInput
                          label={"Confirm Password"}
                          type={"password"}
                          name={"confirmPassword"}
                          placeholder={"Re-enter password"}
                          dataTestId={"passwordconfirm-input"}
                          onchangeHandler={handleChange}
                          className="text-black"
                          required={true}
                        />
                        <FormSelect
                          label={"Sign Up as a:"}
                          name={"role"}
                          dataTestId={"role-input"}
                          items={[
                            { name: "Artist", value: "artist" },
                            { name: "Fan", value: "basic" },
                          ]}
                          onchangeHandler={handleChange}
                          className="text-black"
                          other={false}
                          light={false}
                        />
                        {role == "artist" && (
                          <div className="flex items-center space-x-3">
                            <div>
                              <input
                                type="checkbox"
                                name="terms"
                                className="accent-bloow-blue"
                                required
                                onChange={handleChange}
                              />
                            </div>
                            <div className="text-xs">
                              Accept the{" "}
                              <a
                                target="_blank"
                                href="https://www.iubenda.com/terms-and-conditions/45877133"
                                className="text-bloow-blue"
                              >
                                terms and conditions
                              </a>
                            </div>
                          </div>
                        )}
                        <div className="w-full flex justify-center pt-2">
                          {(role == "artist" && (
                            <div className="w-full">
                              {(email !== "" &&
                                password !== "" &&
                                confirmPassword !== "" &&
                                password === confirmPassword &&
                                terms && (
                                  <MainSubmitButton
                                    text={"Sign up"}
                                    dataTestId={"submit"}
                                    loaderVisibility={isLoading}
                                  />
                                )) || (
                                <div
                                  className="border-2 py-2 border-gray-500 rounded-full w-full flex justify-center cursor-pointer"
                                  onClick={checkMissingFields}
                                  data-testid={"submit"}
                                >
                                  <div className="">Sign up</div>
                                </div>
                              )}
                            </div>
                          )) || (
                            <div className="w-full">
                              {(email !== "" &&
                                password !== "" &&
                                confirmPassword !== "" &&
                                role !== "" &&
                                password === confirmPassword && (
                                  <MainSubmitButton
                                    text={"Sign up"}
                                    dataTestId={"submit"}
                                    loaderVisibility={isLoading}
                                  />
                                )) || (
                                <div
                                  className="border-2 py-2 border-gray-500 rounded-full w-full flex justify-center cursor-pointer"
                                  onClick={checkMissingFields}
                                  data-testid={"submit"}
                                >
                                  <div className="">Sign up</div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="text-xs">
                          Already have an account?{" "}
                          <span
                            className="text-bloow-blue hover:textpurple-700 cursor-pointer"
                            onClick={toggleSignIn}
                            data-testid="signinlink"
                          >
                            Sign in
                          </span>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SignUp;
