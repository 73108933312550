import create from "zustand";

export const userStore = create((set) => ({
  user: null,
  songs: null,
  videos: null,
  events: null,
  publicContent: null,
  storeUser: (user) => set({ user: user }),
  removeUser: () => set({ user: null }),
  setArtistSongs: (songs) => set({ songs: songs }),
  setArtistVideos: (videos) => set({ videos: videos }),
  setArtistEvents: (events) => set({ events: events }),
  setPublicContent: (publicContent) => set({ publicContent: publicContent }),
}));

export const useNavigation = create((set) => ({
  navigation: "home",
  setNavigation: (navigation) => set({ navigation: navigation }),
}));
export const useMpesa = create((set) => ({
  mpesa: false,
  setMpesa: (mpesa) => set({ mpesa: mpesa }),
}));
export const useTippingMpesa = create((set) => ({
  mpesa: false,
  setMpesa: (mpesa) => set({ mpesa: mpesa }),
}));
export const useAlbumMpesa = create((set) => ({
  mpesa: false,
  setMpesa: (mpesa) => set({ mpesa: mpesa }),
}));
export const useAlbumPaypal = create((set) => ({
  paypal: false,
  setPaypal: (paypal) => set({ paypal: paypal }),
}));
export const useTippingPaypal = create((set) => ({
  paypal: false,
  setPaypal: (paypal) => set({ paypal: paypal }),
}));
export const usePaypal = create((set) => ({
  paypal: false,
  setPaypal: (paypal) => set({ paypal: paypal }),
}));
export const useCart = create((set) => ({
  products: [],
  productsCount: 0,
  totalPrice: 0,
  setProducts: (product) =>
    set((state) => {
      // const productExists =
      //   state.products.filter((item) => item._id === product._id)?.length > 0;
      // if (productExists) {
      //   const productWithIdIndex = state.products.findIndex(
      //     (obj) => obj._id === product._id
      //   );
      //   state.products[productWithIdIndex].count++;
      //   state.totalPrice += product.basePrice;
      //   state.productsCount++;
      // }
      // if (!productExists) {
      state.products = product;
      product?.map((pro) => {
        state.totalPrice = pro?.basePrice * pro?.count;
      });

      state.productsCount = state.products.length;
      // }
    }),
  clearCart: () => {
    set((state) => {
      state.products = [];
      state.productsCount = 0;
      state.totalPrice = 0;
    });
  },
  addProduct: (product) => {
    set((state) => {
      const productExists =
        state.products.filter((item) => item._id === product._id)?.length > 0;
      if (productExists) {
        const productWithIdIndex = state.products.findIndex(
          (obj) => obj._id === product._id
        );
        state.products[productWithIdIndex].count++;
        state.productsCount++;
        state.totalPrice += product.basePrice;
      }
      if (!productExists) {
        state.products = [...state.products, product];
        state.productsCount++;
        state.totalPrice += product.basePrice;
      }
    });
  },
  reduceProduct: (product) =>
    set((state) => {
      const productExists =
        state.products.filter((item) => item._id === product._id)?.length > 0;
      if (productExists) {
        const productWithIdIndex = state.products.findIndex(
          (obj) => obj._id === product._id
        );
        if (state.products[productWithIdIndex].count > 1) {
          state.products[productWithIdIndex].count--;
          state.productsCount--;
          state.totalPrice -= product.basePrice;
        }
      }
    }),
  removeProduct: (id) =>
    set((state) => {
      const productWithIdIndex = state.products.findIndex(
        (obj) => obj._id === id
      );

      if (productWithIdIndex > -1) {
        state.totalPrice -= state.products[productWithIdIndex].basePrice;
        state.products.splice(productWithIdIndex, 1);
        state.productsCount--;
      }
    }),
}));
export const usePlaylist = create((set) => ({
  playlist: [],
  currentlyPlaying: 0,
  isPlaying: false,
  setPlaylist: (content) =>
    set((state) => {
      const contentExists =
        state.playlist.filter((song) => song._id === content._id)?.length > 0;
      if (!contentExists) {
        return { playlist: [content, ...state.playlist] };
      }

      const tempPlaylist = state.playlist.filter((song) => {
        if (song._id !== content._id) {
          return song;
        }
      });
      const newPlaylist = [content, ...tempPlaylist];
      return { playlist: newPlaylist };
    }),
  deletePlaylist: () => set({ playlist: [] }),
  changePlaylist: (newPlaylist) => set({ playlist: newPlaylist }),
  setCurrentlyPlaying: (currentlyPlaying) =>
    set({ currentlyPlaying: currentlyPlaying }),
  setIsPlaying: (isPlaying) => set({ isPlaying: isPlaying }),
}));

export const useOpenSidebar = create((set) => ({
  openSidebar: false,
  setOpenSidebar: (openSidebar) => set({ openSidebar: openSidebar }),
}));
