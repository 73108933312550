import { useState, useEffect } from "react";
import { userStore } from "../../../../../stores";
import { getNewReleases } from "../../../../../api/streams";

import { ContentCard, ContentCardLoading } from "../../../cards/contentCards";
import {
  SectionThreeCard,
  SectionThreeCardLoading,
} from "../../mobile/browse/genreAndMoods/overview/sectionThreeCards";

export const SectionThree = () => {
  const user = userStore((state) => state.user);
  const [page, setPage] = useState(1);
  const [finalPage, setFinalPage] = useState(false);
  const [limit, setLimit] = useState(10);
  const [contents, setContents] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getNewReleases(page, limit).then((response) => {
      setLoading(false);
      if (response && response.status === 200) {
        setContents((prevState) => prevState.concat(response.data.contents));
      }
      if (response && response.status === 204) {
        setFinalPage(true);
      }
    });
  }, [user, page, limit]);

  const nextPage = () => {
    if (!finalPage) {
      setPage((prevState) => prevState + 1);
    }
  };
  return (
    <>
      {contents.length > 0 && (
        <div className="w-full flex flex-col">
          <div className="w-full flex items-center justify-between">
            <div className="font-semibold text-white">New Releases for you</div>
          </div>
          <div className="w-full h-full flex flex-col gap-y-3 mt-3">
            {contents?.map((content, key) => {
              return <SectionThreeCard key={key} content={content} />;
            })}
            {!loading && !finalPage && (
              <div className="flex justify-center">
                <div>
                  <div
                    className="bg-bloow-blue px-4 p-1 text-xs text-white rounded font-black capitalize"
                    onClick={nextPage}
                  >
                    View more
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {loading && <ContentLoading />}
    </>
  );
};

export const ContentLoading = () => {
  return (
    <div className="w-full flex flex-col">
      <div className="w-full flex flex-col gap-y-3 mt-3 overflow-y-scroll">
        {Array(4)
          .fill(1)
          .map((el, index) => {
            return <SectionThreeCardLoading />;
          })}
      </div>
    </div>
  );
};
