import React, { useState } from "react";

import { useHistory } from "react-router-dom";
import blurredChart from "../../../../../assets/blurredChart.png";

// IMPORT IMAGES

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
function Leaderboard() {
  const history = useHistory();

  const [leaderBoardActive, setLeaderBoardActive] = useState(0);
  const options = {
    margin: 10,
    nav: true,
    autoplayTimeout: 3000,
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 2,
      },
    },
    navText: [
      '<i class="bi bi-chevron-left text-white fa-10x"></i>',
      '<i class="bi bi-chevron-right text-white"></i>',
    ],
  };
  const handleLeaderBoardTodayChange = () => {
    setLeaderBoardActive(0);
  };

  const handleLeaderBoardWeekChange = () => {
    setLeaderBoardActive(1);
  };

  const handleLeaderBoardMonthChange = () => {
    setLeaderBoardActive(2);
  };
  const artists = [
    {
      Name: "Jua Cali",
      Image:
        "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fvideos%2Fjuacali-33.png?alt=media&token=3bc96eff-353e-4639-862c-264f2ff810d2",
      Link: "/player/premium/album/641325a32b602ff63687f5b6",
    },
    // {
    //   Name: "Khartoomoney",
    //   Image:
    //     "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fimages%2Fkhatoomoney1.png?alt=media&token=3caf59cb-be69-42b8-83d2-dc3463cda1df",
    //   Link: "/player/browse/artist/6217310555f0306e4be898a2",
    // },
    {
      Name: "Qqu",
      Image:
        "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fimages%2Fqqu.png?alt=media&token=1e3b16d1-ecc3-4f3e-bc93-372a04597af4",
      Link: "/player/browse/artist/620bd0c43a1bf303b4641caf",
    },
    {
      Name: "Tingseh",
      Image:
        "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fimages%2Ftingseh.png?alt=media&token=08f5b415-f44a-43f0-9909-4511584b37f1",
      Link: "/player/browse/artist/62277e7d5623cd0d1b3bcf2a",
    },
    {
      Name: "Vesertile",
      Image:
        "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fimages%2Fversatile.png?alt=media&token=e9877059-7cc5-4a20-b339-893fbecdec42",
      Link: "/player/browse/artist/620bd3e33a1bf303b4641d63",
    },

    {
      Name: "Kuky",
      Image:
        "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fimages%2Fkuky.png?alt=media&token=a5727f5e-58cd-40e0-b179-d6b84f85ec2c",
      Link: "/player/browse/artist/634e8d730b3fb4b44f20297b",
    },
    {
      Name: "Lynn(Diva)",
      Image:
        "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fimages%2Flynn%20Diva.png?alt=media&token=f5a64b98-946d-46dd-986e-4cc8f4d5a04c",
      Link: "/player/browse/artist/62220dab5623cd0d1b3bccd2",
    },
  ];
  // const artists = [
  //   {
  //     Name: "Winrose",
  //     Image:
  //       "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fimages%2Fwinrose.png?alt=media&token=4702a1bd-4092-4a6c-8e15-76b0b7bc944e",
  //     Link: "/player/browse/artist/62148e3755f0306e4be89692",
  //   },
  //   {
  //     Name: "Qqu",
  //     Image:
  //       "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fimages%2Fqqu.png?alt=media&token=3ab68587-0eed-4502-9f00-0af9d93b013d",
  //     Link: "/player/browse/artist/620bd0c43a1bf303b4641caf",
  //   },
  //   {
  //     Name: "Tingseh",
  //     Image:
  //       "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fimages%2Ftingseh.png?alt=media&token=4586336a-d652-4145-b119-981a900f584e",
  //     Link: "/player/browse/artist/62277e7d5623cd0d1b3bcf2a",
  //   },
  //   {
  //     Name: "Vesertile",
  //     Image:
  //       "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fimages%2Fversatile.png?alt=media&token=504e7474-a6b7-4e9f-8fa8-4b306d76ac0f",
  //     Link: "/player/browse/artist/620bd3e33a1bf303b4641d63",
  //   },
  //   {
  //     Name: "Khartoomoney",
  //     Image:
  //       "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fimages%2Fkhartoomoney.png?alt=media&token=a2bd65f6-3488-4ed7-a8f2-89281dae237e",
  //     Link: "/player/browse/artist/6217310555f0306e4be898a2",
  //   },
  //   {
  //     Name: "Lynn(Diva)",
  //     Image:
  //       "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fimages%2Flynn%20Diva.png?alt=media&token=f5a64b98-946d-46dd-986e-4cc8f4d5a04c",
  //     Link: "/player/browse/artist/62220dab5623cd0d1b3bccd2",
  //   },
  // ];

  return (
    <>
      <div className="leaderboard--background space-y-10 ">
        <h2 className="text-center text-4xl py-2 text-shrink ">
          Bloow Leaderboard
        </h2>

        <div className="  w-11/12 mx-auto  overflow-hidden ">
          <OwlCarousel {...options}>
            {artists &&
              artists.slice(0, 2).map((item, index) => (
                <div
                  className="relative  cursor-pointer mb-3 "
                  onClick={() => {
                    history.push(item?.Link);
                  }}
                >
                  <img
                    className="lazyload"
                    loading="lazy"
                    src={blurredChart}
                    data-src={item.Image}
                    alt={item.Name}
                  />
                </div>
              ))}
          </OwlCarousel>
          <OwlCarousel {...options}>
            {artists &&
              artists.slice(2, 4).map((item, index) => (
                <div
                  className="relative  cursor-pointer mb-3 "
                  onClick={() => {
                    history.push(item?.Link);
                  }}
                >
                  <img
                    className="lazyload"
                    loading="lazy"
                    data-src={item.Image}
                    alt={item.Name}
                  />
                </div>
              ))}
          </OwlCarousel>
          <OwlCarousel {...options}>
            {artists &&
              artists.slice(4).map((item, index) => (
                <div
                  className="relative  cursor-pointer mb-3 "
                  onClick={() => {
                    history.push(item?.Link);
                  }}
                >
                  <img src={item.Image} alt={item.Name} />
                </div>
              ))}
          </OwlCarousel>
        </div>

        <div className="flex items-center justify-center gap-10 pb-6">
          {/* <button
            onClick={handleLeaderBoardTodayChange}
            className={` ${
              leaderBoardActive === 0
                ? "bg-activeButton text-blue"
                : "nav--background"
            } py-2 px-4  rounded-xl text-xs md:text-base md:font-bold`}
          >
            Today
          </button> */}
          {/* <button
            onClick={handleLeaderBoardWeekChange}
            className={` ${
              leaderBoardActive === 1
                ? "bg-activeButton text-blue"
                : "nav--background"
            }  py-2 px-4 rounded-xl text-xs md:text-base md:font-bold`}
          >
            This Week
          </button>
          <button
            onClick={handleLeaderBoardMonthChange}
            className={` ${
              leaderBoardActive === 2
                ? "bg-activeButton text-blue"
                : "nav--background"
            } py-2 px-4 rounded-xl text-xs md:text-base md:font-bold`}
          >
            This Month
          </button> */}
        </div>
      </div>
    </>
  );
}

export default Leaderboard;
