import { Suspense, lazy, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Navbar } from "./components/navbar";
import Sidebar from "../../../components/sidebar/sidebar";
import { MusicPlayer } from "../../../components/music/musicPlayer";
import { Switch, Route } from "react-router-dom";
import { Spinner } from "../../../components/utils/spinningLoader";
const Player = () => {
  const Home = lazy(() => import("./components/home"));
  const Library = lazy(() => import("./components/library"));
  const Browse = lazy(() => import("./components/browse"));
  const Queue = lazy(() => import("./components/queue"));
  const Premium = lazy(() => import("./components/premium/premium"));
  const BookArtist = lazy(() =>
    import("./components/bookArtist/bookArtist.js")
  );
  const BookArtistPage = lazy(() =>
    import("./components/bookArtist/bookArtistPage.js")
  );
  const PremiumAlbum = lazy(() => import("./components/premium/premiumAlbum"));
  const PurchusedContent = lazy(() =>
    import("./components/premium/purchasedContent")
  );
  const Merchandise = lazy(() =>
    import("./components/merchandise/merchandise")
  );
  const UserBookingsPage = lazy(() =>
    import("./components/bookArtist/userBookingsPage")
  );
  const ArtistBookingsPage = lazy(() =>
    import("./components/bookArtist/artistBookingsPage")
  );
  const [showPlayer, setShowPlayer] = useState();
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    if (/^\/player\/merchandise\b[\/\w|\d]*/g.test(history.location.pathname)) {
      setShowPlayer(false);
    } else {
      setShowPlayer(true);
    }
  }, [location]);

  return (
    <div className="flex bg-midnight  w-screen h-screen fixed">
      <div className="h-full w-max">
        <Sidebar />
      </div>
      <div className=" flex-grow  overflow-y-scroll pb-10">
        <Navbar />
        <Switch>
          <Route exact path={"/player"}>
            <Suspense fallback={<Spinner />}>
              <Home />
            </Suspense>
          </Route>

          <Route exact path="/player/library">
            <Suspense fallback={<Spinner />}>
              <Library />
            </Suspense>
          </Route>
          <Route path="/player/browse">
            <Suspense fallback={<Spinner />}>
              <Browse />
            </Suspense>
          </Route>
          <Route path="/player/merchandise">
            <Suspense fallback={<Spinner />}>
              <Merchandise />
            </Suspense>
          </Route>
          <Route exact path="/player/book">
            <Suspense fallback={<Spinner />}>
              <BookArtist />
            </Suspense>
          </Route>
          <Route exact path="/player/book/bookings">
            <Suspense fallback={<Spinner />}>
              <UserBookingsPage />
            </Suspense>
          </Route>
          <Route exact path="/player/book/bookings/artist">
            <Suspense fallback={<Spinner />}>
              <ArtistBookingsPage />
            </Suspense>
          </Route>
          <Route exact path="/player/book/:artistId">
            <Suspense fallback={<Spinner />}>
              <BookArtistPage />
            </Suspense>
          </Route>
          <Route exact path="/player/premium">
            <Suspense fallback={<Spinner />}>
              <Premium />
            </Suspense>
          </Route>
          <Route exact path="/player/premium/album/:albumId">
            <Suspense fallback={<Spinner />}>
              <PremiumAlbum />
            </Suspense>
          </Route>
          <Route exact path="/player/premium/purchased">
            <Suspense fallback={<Spinner />}>
              <PurchusedContent />
            </Suspense>
          </Route>
          <Route path="/player/queue">
            <Suspense fallback={<Spinner />}>
              <Queue />
            </Suspense>
          </Route>
        </Switch>

        {showPlayer && (
          <div className="fixed  z-50 w-full bottom-0 left-0 flex rounded-t-md">
            <div className=" w-44 mr-6"></div>
            <div className="w-full bg-black text-white border border-gray-500 rounded-t-md py-2">
              <MusicPlayer
                openQueue={() => {
                  history.push("/player/queue");
                }}
                autoPlay={true}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Player;
