import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_MAIN_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": process.env.REACT_APP_BASE_URL,
  },
  withCredentials: true,
});

export const registerUser = async (user) => {
  return await api
    .post("/users/register", user)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const loginUser = async (user) => {
  return await api
    .post("/users/login", user)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const refreshToken = async () => {
  return await api
    .get("/users/refreshSession")
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const addAuthToken = (token) => {
  api.defaults.headers.common["Authorization"] = "Bearer " + token;
};

export const removeAuthToken = () => {
  api.defaults.headers.common["Authorization"] = "";
};

export const postContent = async (content) => {
  return await api
    .post("/artistContent/upload", content)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const updateContent = async (data) => {
  return await api
    .patch(`/artistContent/edit/${data.id}`, data.editedContent)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const getArtistContent = async (page, limit, data) => {
  return await api
    .post(`/artistContent/?page=${page}&limit=${limit}`, data)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const updateUser = async (user) => {
  return await api
    .patch("/user/edit", user)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const addPhoneNumber = async (user) => {
  return await api
    .patch("/user/addPhoneNumber", user)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const checkPhoneNumber = async (user) => {
  return await api
    .post("/user/checkPhoneNumberIfExists", user)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const validateUser = async (oldPassword) => {
  return await api
    .post("/users/validatePassword", oldPassword)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const updatePassword = async (newPassword) => {
  return await api
    .patch("/users/changePassword", newPassword)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const getContentPerCatgory = async (category) => {
  return await api
    .get(
      `/user/allContent?page=1&limit=1000&contentCategory=${category}&albumGenre=${category}`
    )
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const getContentForCatgory = async (page, limit, data) => {
  return await api
    .post(`/artistContent/category/?page=${page}&limit=${limit}`, data)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const voteForContent = async (contentId, data) => {
  return await api
    .post(`/mpesa/stk/${contentId}`, data)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const logoutUser = async () => {
  return await api
    .post(`/users/logout`)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const topUpWallet = async (amount) => {
  return await api
    .post(`/wallet/topUp`)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const createAlbum = async (album) => {
  return await api
    .post(`/album/create`, album)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const getArtistAlbums = async () => {
  return await api
    .get(`/album`)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const getAlbumsByGenre = async (data, page, limit) => {
  return await api
    .post(`album/getAlbumsByGenre/?page=${page}&limit=${limit}`, data)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const getAllGenres = async () => {
  return await api
    .get(`/contentCategory/allContentCategories`)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const mpesaConfirmPaid = async (data) => {
  return await api
    .post(`/userActivity/confirmVote`, data)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

// This uses Twillio API
export const sendOTPMessage = async (data) => {
  return await api
    .post(`/otp/sendOtp`, data)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

// This uses Twillio API
export const verifyOTP = async (data) => {
  return await api
    .post(`/otp/confirmOtp`, data)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const getArtistPublicContent = async (data, page, limit) => {
  return await api
    .post(
      `/artistContent/userGetArtistContent/?page=${page}&limit=${limit}`,
      data
    )
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const likeContent = async (data) => {
  return await api
    .post(`/userActivity/likeContent`, data)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const likeAlbum = async (data) => {
  return await api
    .post(`/album/albumLike`, data)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const followArtist = async (data) => {
  return await api
    .post(`/userActivity/follow`, data)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const getArtistInfo = async (data) => {
  return await api
    .post(`/userActivity/artistInfo`, data)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const getArtistPublicAlbums = async (data) => {
  return await api
    .post(`/userActivity/viewAlbums`, data)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const getSpecificArtistAlbums = async (data, page, limit) => {
  return await api
    .post(`/album/artistAlbums/?page=${page}&limit=${limit}`, data)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const getSpecificAlbum = async (data) => {
  return await api
    .get(`/album/userGetSpecificAlbum/${data}`)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const getNotifications = async () => {
  return await api
    .post(`/notification/notifications`)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const clearNotifications = async () => {
  return await api
    .post(`/notification/updateNotifications`)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const getContent = async (data) => {
  return await api
    .post(`/userActivity/getSpecificContent`, data)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const getSpecificContent = async (data) => {
  return await api
    .get(`/artistContent/userGetSpecificContent/${data}`)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const getAllContent = async () => {
  return await api
    .get(`/user/allContent?page=1&limit=10000`)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const getAllAudioContent = async () => {
  return await api
    .get(`/user/allContent?page=1&limit=10000&contentType=audio`)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const getAllVideoContent = async (page) => {
  return await api
    .get(`/user/allContent?page=${page}&limit=20&contentType=video`)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const generateLink = async (contentId, data) => {
  return await api
    .post(`/url/generateLink/${contentId}`, data)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const getCategoriesForGender = async (category) => {
  return await api
    .post(`/merchandize/getMerchandizeCategories`, category)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const getCategoryOptions = async (category) => {
  return await api
    .post(`/merchandize/getMerchandizesbyCategoryId`, category)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const artistCustomizeMerch = async (merchObj) => {
  return await api
    .post(`/merchandize/customizeMerchandize`, merchObj)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const getArtistMerchandise = async (artistObj) => {
  return await api
    .post(`/merchandize/customizeMerchandize`, artistObj)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const creatMerchandiseOrderMpesa = async (artistObj) => {
  return await api
    .post(`/merchandize/merchandizeOrderStkPush`, artistObj)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const getAllArtistMerchandise = async () => {
  return await api
    .post(`/merchandize/getAllCustomMerchandize`)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const createEvent = async (event) => {
  return await api
    .post(`/events/createEvent`, event)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const getArtistEvents = async (info) => {
  return await api
    .post(`/events/getArtistEvents`, info)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const getEvent = async (info) => {
  return await api
    .post(`/events/getSpecificEvent`, info)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const deleteContent = async (contentId) => {
  return await api
    .delete(`/artistContent/delete/${contentId}`)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const resetEmail = async (emailObj) => {
  return await api
    .patch(`/users/forgotPassword`, emailObj)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const verifyResetLink = async (emailObj) => {
  return await api
    .post(`/users/verifyJwtResetLink`, emailObj)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const searchForAnything = async (searchObj) => {
  return await api
    .post(`/Search`, searchObj)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};

export const changePassword = async (passwordObj) => {
  return await api
    .patch(`/users/resetPassword`, passwordObj)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const getUserInvoices = async (page, limit) => {
  return await api
    .get(`/invoices/getUserConfirmedInvoices/?page=${page}&limit=${limit}`)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const getTopTenLikedSongs = async () => {
  return await api
    .get("/artistContent/topLiked")
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const getTopTenVotedSongs = async () => {
  return await api
    .get("/artistContent/topVoted")
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const getBloowLeaderboard = async () => {
  return await api
    .get("/artistContent/leaderboard")
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const getUserAlbums = async () => {
  return await api
    .get("/album")
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const postLockContent = async (data, id) => {
  return await api
    .post(`/artistContent/addContentToPremiumContent/${id}`, data)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const setPremiumAlbum = async (data, id) => {
  return await api
    .patch(`/premiumAlbum/edit/${id}`, data)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const postLockAlbum = async (data, id) => {
  return await api
    .post(`/premiumAlbum/add/${id}`, data)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const postUnlockContent = async (data) => {
  return await api
    .post(`/artistContent/removePremiumContent`, data)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const postUnlockAlbum = async (id) => {
  return await api
    .post(`/premiumAlbum/remove/${id}`)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const getArtistPremiumAlbum = async (id) => {
  return await api
    .get(`/premiumAlbum/albums/${id}`)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const getUserPremiumAlbum = async (id) => {
  return await api
    .get(`/premiumAlbum/album/${id}`)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const getUserPurchasedAlbum = async (id) => {
  return await api
    .get(`/premiumAlbum/user/album/${id}`)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const getArtistPremiumContent = async (id) => {
  return await api
    .get(`/content/user/get/artistPremiumContent/${id}`)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const getUserPremiumAlbums = async () => {
  return await api
    .get(`/premiumAlbum/user/albums`)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const getAllPremiumContent = async (page) => {
  return await api
    .get(`/content?page=${page}&limit=10`)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const getUserPremiumContent = async () => {
  return await api
    .get("/content/user/premium")
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const getAllPremiumAlbums = async () => {
  return await api
    .get("/premiumAlbum/albums")
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const getArtistPremiumAlbums = async (id) => {
  return await api
    .get(`/premiumAlbum/albums/${id}`)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const getAllPremiumArtists = async (page) => {
  return await api
    .get(`/content/premiumArtists?page=${page}&limit=10`)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const postArtistBookable = async (data) => {
  return await api
    .post("/bookings/availability", data)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const getBookableArtists = async () => {
  return await api
    .get("/bookings/availableArtists")
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const getSpecificBookableArtists = async (id) => {
  return await api
    .get(`/bookings/artist/${id}`)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const postBookArtist = async (id, data) => {
  return await api
    .post(`/bookings/book/${id}`, data)
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const clientGetBookings = async () => {
  return await api
    .post("/bookings/client/bookings")
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
export const artistGetBookings = async () => {
  return await api
    .post("/bookings/artist/bookings")
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
};
