import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_MAIN_API_BASE_URL,
    headers: {
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': process.env.REACT_APP_BASE_URL,
    },
    withCredentials: true
})


export const getUsersRecentPlays = async (page, limit, userId) => {

    return await api.post(`/stream/getUserStreams/?page=${page}&limit=${limit}`, { userId: userId }).then((response) => response).catch((error) => {
        return error.response
    })
}
export const getDevicesRecentPlays = async (ipAddress, page, limit) => {

    return await api.post(`/stream/getDeviceStreams/?page=${page}&limit=${limit}`, { ipAddress: ipAddress }).then((response) => response).catch((error) => {
        return error.response
    })
}

export const createUsersRecentPlays = async (data) => {

    return await api.post(`/stream/createStream`, data).then((response) => response).catch((error) => {
        return error.response
    })
}

export const getRecommendedArtists = async (page, limit) => {

    return await api.get(`/user/artistRecommendation/?page=${page}&limit=${limit}`).then((response) => response).catch((error) => {
        return error.response
    })
}

export const getNewReleases = async (page, limit) => {

    return await api.get(`/artistContent/getRecentlyReleasedContents/?page=${page}&limit=${limit}`).then((response) => response).catch((error) => {
        return error.response
    })
}

export const getNewReleasesByGenre = async (page, limit, genre) => {

    return await api.get(`/artistContent/recentlyReleasedContentsByCategory/${genre}/?page=${page}&limit=${limit}`).then((response) => response).catch((error) => {
        return error.response
    })
}