import React, { useEffect, useState } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import logo from "../../../../../assets/logo.svg";
import { AiFillHome, AiOutlineRight } from "react-icons/ai";
import { IoMdMusicalNote } from "react-icons/io";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { BiWallet } from "react-icons/bi";
import { GiMusicSpell, GiClothes } from "react-icons/gi";
import { MdBookmarkBorder } from "react-icons/md";

import { userStore, useOpenSidebar } from "../../../../../stores/index";

function Sidebar() {
  const [navigation, setNavigation] = useState();
  const [minimizeMenu, setMininizeMenu] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const openSidebar = useOpenSidebar((state) => state.openSidebar);

  useEffect(() => {
    if (/^\/player\b\/*$/g.test(history.location.pathname)) {
      setNavigation("home");
    } else if (/^\/\b\/*$/g.test(history.location.pathname)) {
      setNavigation("index");
    } else if (
      /^\/player\/merchandise\b[\/\w|\d]*/g.test(history.location.pathname)
    ) {
      setNavigation("merchandise");
    } else if (
      /^\/player\/browse\b[\/\w|\d]*/g.test(history.location.pathname)
    ) {
      setNavigation("music");
    } else if (
      /^\/player\/premium\/purchased\b[\/\w|\d]*/g.test(
        history.location.pathname
      )
    ) {
      setNavigation("purchased");
    } else if (
      /^\/player\/premium\b[\/\w|\d]*/g.test(history.location.pathname)
    ) {
      setNavigation("premium");
    } else if (/^\/player\/tv\b[\/\w|\d]*/g.test(history.location.pathname)) {
      setNavigation("tv");
    } else if (
      /^\/player\/radio\b[\/\w|\d]*/g.test(history.location.pathname)
    ) {
      setNavigation("radio");
    } else if (
      /^\/player\/store\b[\/\w|\d]*/g.test(history.location.pathname)
    ) {
      setNavigation("store");
    } else if (
      /^\/player\/studio\b[\/\w|\d]*/g.test(history.location.pathname)
    ) {
      setNavigation("studio");
    } else if (
      /^\/player\/finance\b[\/\w|\d]*/g.test(history.location.pathname)
    ) {
      setNavigation("finance");
    }
  }, [location]);

  return (
    <>
      {openSidebar && (
        <ExpandedSidebar
          clickToggler={setMininizeMenu}
          setNavigation={setNavigation}
          navigation={navigation}
          history={history}
        />
      )}
    </>
  );
}

const ExpandedSidebar = ({
  clickToggler,
  setNavigation,
  navigation,
  history,
}) => {
  const user = userStore((state) => state.user);
  const setOpenSidebar = useOpenSidebar((state) => state.setOpenSidebar);
  const openSidebar = useOpenSidebar((state) => state.openSidebar);

  const selected =
    "pl-5 py-4 w-full flex items-center justify-between cursor-pointer text-bloow-blue";
  const notSelected =
    "pl-5 py-4 w-full flex items-center justify-between cursor-pointer text-white hover:text-bloow-blue";

  return (
    <div className="h-full w-56 bg-black shadow-lg flex flex-col transition ease-in-out duration-300 ">
      <div className=" rounded-md  ">
        <div className=" flex  pt-6 pb-10 px-3">
          <div className="  text-center bg-white pb-2 rounded-lg  ">
            <img src={logo} alt="Bloow logo" className=" w-28 h-10 mt-3" />
          </div>
        </div>
        <Link to={"/player"} onClick={() => setOpenSidebar(!openSidebar)}>
          <div
            className={navigation == "home" ? selected : notSelected}
            onClick={() => setNavigation("home")}
          >
            <div className="flex items-center ">
              <AiFillHome className=" " />

              <div className="pl-4 pr-8 font-bold text-xs tracking-wide ">
                Home
              </div>
            </div>
            <AiOutlineRight className="  " />
          </div>
        </Link>

        <Link
          to={"/player/premium"}
          onClick={() => setOpenSidebar(!openSidebar)}
        >
          <div
            className={navigation == "premium" ? selected : notSelected}
            onClick={() => setNavigation("premium")}
          >
            <div className="flex items-center">
              <IoMdMusicalNote className="  " />

              <div className="pl-4 pr-8  font-bold  text-xs tracking-wide">
                Debut
              </div>
            </div>
            <AiOutlineRight className=" " />
          </div>
        </Link>
        <Link
          to={"/player/premium/purchased"}
          onClick={() => setOpenSidebar(!openSidebar)}
        >
          <div
            className={navigation == "purchased" ? selected : notSelected}
            onClick={() => setNavigation("purchased")}
          >
            <div className="flex items-center">
              <GiMusicSpell className="  " />

              <div className="pl-4 pr-8  font-bold  text-xs tracking-wide">
                Purchased
              </div>
            </div>
            <AiOutlineRight className=" " />
          </div>
        </Link>

        {user && user.role === "artist" && (
          <div
            className={navigation == "finance" ? selected : notSelected}
            onClick={() => {
              history.push("/dashboard/artist/finance");
            }}
          >
            <div className="flex items-center ">
              <BiWallet className=" " />

              <div className="pl-4 font-bold text-xs tracking-wide">Wallet</div>
            </div>
            <AiOutlineRight className=" " />
          </div>
        )}
        <Link
          to={"/player/merchandise"}
          onClick={() => setOpenSidebar(!openSidebar)}
        >
          <div
            className={navigation == "merchandise" ? selected : notSelected}
            onClick={() => setNavigation("machandise")}
          >
            <div className="flex items-center">
              <GiClothes className="  " />

              <div className="pl-4 pr-4  font-bold  text-xs tracking-wide">
                Merchandise
              </div>
            </div>
            <AiOutlineRight className=" " />
          </div>
        </Link>
        <Link to={"/player/book"} onClick={() => setOpenSidebar(!openSidebar)}>
          <div
            className={navigation == "book" ? selected : notSelected}
            onClick={() => setNavigation("book")}
          >
            <div className="flex items-center">
              <MdBookmarkBorder className="  " />

              <div className="pl-4 pr-12  font-bold  text-xs tracking-wide">
                Bookings
              </div>
            </div>
            <AiOutlineRight className=" " />
          </div>
        </Link>
        <Link to={"/home"} onClick={() => setOpenSidebar(!openSidebar)}>
          <div
            className={navigation == "index" ? selected : notSelected}
            onClick={() => setNavigation("index")}
          >
            <div className="flex items-center">
              <BsFillInfoCircleFill className="  " />

              <div className="pl-4 pr-12  font-bold  text-xs tracking-wide">
                About
              </div>
            </div>
            <AiOutlineRight className=" " />
          </div>
        </Link>
      </div>
    </div>
  );
};
export default Sidebar;
