import React from "react";

function Awards() {
  return (
    <>
      <div className="awards--section flex  justify-center">
        <h2 className="text-white text-3xl my-3  text-center mx-auto">
          Bloow Awards
        </h2>
      </div>
    </>
  );
}

export default Awards;
