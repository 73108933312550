import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getAllGenres } from "../../../../api/auth";
import LeaderBoard from "../../../../components/utils/player/desktop/home/leaderBoard";
import { SectionTwo } from "../../../../components/utils/player/mobile/home/sectionTwo";
import { SectionThree } from "../../../../components/utils/player/mobile/home/sectionThree";
import TopTenSongs from "../../../../components/utils/player/desktop/home/topTenSongs";
import TopVotedSongs from "../../../../components/utils/player/mobile/home/topVotedSongs";
import { RecommendedStreams } from "../../../../components/utils/player/mobile/home/RecommendedStreams";
import GenreSection from "../../../../components/utils/player/mobile/home/genreSection";
import Debut from "../../../../components/utils/player/mobile/home/debut";
import AllPremiumArtists from "../../../../components/utils/player/desktop/premium/allPremiumArtists";
import PlayerNavbar from "./navigation/playerNavbar";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

import { usePlaylist } from "../../../../stores";
import { userStore } from "../../../../stores";

const Home = () => {
  const user = userStore((state) => state.user);
  const [openGenre, setOpenGenre] = useState(false);
  const [loadingGenres, setLoadingGenres] = useState(true);
  const [genres, setGenres] = useState([]);

  useEffect(() => {
    setLoadingGenres(true);
    getAllGenres().then((response) => {
      if (response && response.status === 200) {
        setGenres(response.data.contentCategories);
        setLoadingGenres(false);
      }
    });
  }, []);

  return (
    <div className="w-full     ">
      <PlayerNavbar />
      <div className="w-full px-2  flex flex-col gap-y-2 gap-x-4 ">
        {/* <SectionOne addToPlaylist={play} /> */}
        {user && <SectionTwo />}
        <GenreSection />

        {/* <Debut /> */}
        <SectionThree />
        <AllPremiumArtists />
        <TopTenSongs />
        <LeaderBoard />
        <TopVotedSongs />
        <RecommendedStreams />
      </div>
      <SlidingPane
        isOpen={openGenre}
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setOpenGenre(false);
        }}
        from="bottom"
        width="100"
        hideHeader={true}
      >
        <div className="w-full flex flex-col">
          <div className="sticky w-full flex justify-between items-center -m-4">
            <div
              className="p-2 hover:bg-gray-100 text-gray-500"
              onClick={() => {
                setOpenGenre(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
            <div className="text-gray-500 font-bold">Pick a genre</div>
            <div></div>
          </div>
          <div className="mt-6">
            <div className="w-full border-2 rounded flex justify-center py-1 font-bold mb-3">
              <div>All Genre</div>
            </div>
            {genres.map((genre) => {
              return (
                <div className="w-full border-2 rounded flex justify-center py-1 font-bold mb-3">
                  <Link to={`/player/browse/genre/${genre.category}`}>
                    <div>{genre.category}</div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </SlidingPane>
    </div>
  );
};

export default Home;

//
