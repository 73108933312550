import { useState, useEffect } from "react";
import { userStore } from "../../../../../stores";

import {
  ContentRoundCard,
  ContentCard,
  ContentRoundCardLoading,
  ContentCardLoading,
} from "../../../cards/contentCards";

export const SectionTwo = ({ play }) => {
  const user = userStore((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const [contents, setContents] = useState([]);

  useEffect(() => {
    pullUserRecentPlays();
  }, [user, page, limit]);

  const pullUserRecentPlays = async () => {
    setLoading(true);
    if (user) {
      setContents(user.recentStreams);
      setLoading(false);
    }
  };

  const pullNextPage = () => {
    setPage((prevState) => prevState + 1);
  };

  return (
    <>
      {!loading && contents.length > 0 && (
        <div className="w-full flex flex-col">
          <div className="w-full flex items-center justify-between">
            <div className="font-semibold text-white">Recently Played</div>
            <div className="flex items-center text-gray-500 ">
              <div className="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`h-6 w-6 ${
                    page === 1 ? "text-white" : "cursor-pointer "
                  }`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </div>
              <div onClick={pullNextPage} className="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 cursor-pointer text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="w-full h-56 flex gap-x-6 mt-3 overflow-y-scroll no-scrollbar">
            {contents?.map((content, index) => {
              if (index === 0 && content.contentId !== null) {
                return (
                  <ContentRoundCard
                    key={index}
                    content={content.contentId}
                    onClickHandler={play}
                  />
                );
              } else if (content.contentId !== null) {
                return (
                  <ContentCard
                    key={index}
                    content={content?.contentId}
                    onClickHandler={play}
                  />
                );
              }
            })}
          </div>
        </div>
      )}
      {loading && <SectionTwoLoading />}
    </>
  );
};

export const SectionTwoLoading = () => {
  return (
    <div className="w-full flex flex-col ">
      <div className="w-full flex items-center justify-between">
        <div className="w-32 h-7 bg-gray-300 animate-pulse rounded-md"></div>
        <div className="w-24 h-7 bg-gray-300 animate-pulse rounded-md"></div>
      </div>
      <div className="w-full h-56 flex gap-x-6 mt-3 overflow-y-scroll">
        {Array(6)
          .fill(1)
          .map((el, index) => {
            if (index === 0) {
              return <ContentRoundCardLoading />;
            } else {
              return <ContentCardLoading />;
            }
          })}
      </div>
    </div>
  );
};
