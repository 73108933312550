import React, { useState, useEffect } from "react";
import { ArtistCard, ArtistCardLoading } from "../../../cards/artistCards";
import { getBloowLeaderboard } from "../../../../../api/auth";
function LeaderBoard() {
  const [artists, setArtists] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getBloowLeaderboard().then((response) => {
      setLoading(false);
      if (response && response.status === 200) {
        setArtists(response.data.leaderboard);
      }
      if (response && response.status === 204) {
        return;
      }
    });
  }, []);
  return (
    <>
      {(loading && <LeaderBoardLoading />) || (
        <div className="w-full flex flex-col py-5">
          <div className="w-full flex items-center justify-between">
            <div className="font-semibold text-white">Bloow LeaderBoard</div>
          </div>
          <div className="w-full h-56 flex gap-x-6 mt-3 overflow-y-scroll no-scrollbar">
            {artists.length > 0 &&
              artists?.map((content, key) => {
                return (
                  <ArtistCard
                    key={key}
                    content={content}
                    onClickHandler={() => {}}
                  />
                );
              })}
          </div>
        </div>
      )}
    </>
  );
}
const LeaderBoardLoading = () => {
  return (
    <div className="w-full flex flex-col">
      <div className="w-full flex items-center justify-between">
        <div className="w-32 h-7 bg-gray-300 animate-pulse rounded-md"></div>
        <div className="w-16 h-7 bg-gray-300 animate-pulse rounded-md"></div>
      </div>
      <div className="w-full h-52 flex gap-x-6 mt-3 overflow-y-scroll no-scrollbar">
        {Array(6)
          .fill(1)
          .map((el, index) => {
            return <ArtistCardLoading />;
          })}
      </div>
    </div>
  );
};

export default LeaderBoard;
