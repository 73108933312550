import React from "react";

import facebookBackground from "../../../../../assets/facebook-background.png";
import instagramBackground from "../../../../../assets/instagram-background.png";
import twitterBackground from "../../../../../assets/twitter-background.png";
import linkedinBackground from "../../../../../assets/linkedin-background.png";

// OWL CAROUSEL
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function SocialMedia() {
  const options = {
    loop: true,
    margin: 10,
    nav: true,
    autoplay: true,
    autoplayTimeout: 3000,
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
    navText: [
      '<i class="bi bi-chevron-left"></i>',
      '<i class="bi bi-chevron-right"></i>',
    ],
  };

  return (
    <div className=" py-10 p-5 bg-white">
      <div className="text-center text-3xl pb-4">Social Media</div>
      <div className=" items-center md:pl-28 md:pr-28 flex">
        <OwlCarousel {...options}>
          <a
            href="https://web.facebook.com/Bloow-Global-106379321845229?_rdc=1&_rdr"
            target="_blank"
          >
            <div className="relative">
              <img
                className="lazyload"
                loading="lazy"
                data-src={facebookBackground}
                alt="tiktok"
              />
            </div>
          </a>

          <a href="https://www.instagram.com/bloowglobal" target="_blank">
            <div className="relative">
              <img
                className="lazyload"
                loading="lazy"
                data-src={instagramBackground}
                alt="instagram"
              />
            </div>
          </a>

          <a href="https://twitter.com/Bloowglobal" target="_blank">
            <div className="relative">
              <img
                className="lazyload"
                loading="lazy"
                data-src={twitterBackground}
                alt="twitter"
              />
            </div>
          </a>

          <a
            href="https://www.linkedin.com/company/bloow-global"
            target="_blank"
          >
            <div className="relative">
              <img
                className="lazyload"
                loading="lazy"
                data-src={linkedinBackground}
                alt="linkedin"
              />
            </div>
          </a>
        </OwlCarousel>
      </div>
    </div>
  );
}

export default SocialMedia;
